import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  IconLink,
  OwnUpBody,
  OwnUpGridWrapper,
  OwnUpSmallHeadlineMedium
} from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import { Link } from 'gatsby';
import React from 'react';
import { stepExistingOfferValueProps } from '../../../data/content/how-it-works';
import { IconMultipleInner } from '../../icon-multiple';
import {
  CallToActionSection,
  DesktopDisplay,
  IconMultipleWrapper,
  LetsTalkButton,
  MobileDisplay,
  MobileNeedMoreKnowledge,
  Section
} from './elements';

const headerA = 'Already have a loan offer?';
const headerB = 'We can help.';

const CheckOutOurFaqs = () => {
  const trackingInstance = useTrackingInstance();
  return (
    <IconLink
      icon={ArrowRightIcon}
      iconPlacement="right"
      link="/faq"
      component={Link}
      to="/faq"
      style={{ display: 'inline-block' }}
      onClick={() =>
        trackingInstance.track('clickFaqCTA-howItWorks', {
          'page-location': 'how-it-works-check-out-our-faqs-link'
        })
      }
    >
      Check out our FAQs
    </IconLink>
  );
};

export const StepExistingOffer = () => {
  const trackingInstance = useTrackingInstance();

  return (
    <Section aria-label={headerA}>
      <OwnUpGridWrapper>
        <DesktopDisplay>
          <OwnUpSmallHeadlineMedium variant="h2">{headerA}</OwnUpSmallHeadlineMedium>
          <OwnUpSmallHeadlineMedium variant="h2">{headerB}</OwnUpSmallHeadlineMedium>
        </DesktopDisplay>
        <MobileDisplay>
          <OwnUpSmallHeadlineMedium variant="h2">
            {headerA} {headerB}
          </OwnUpSmallHeadlineMedium>
        </MobileDisplay>
        <IconMultipleWrapper>
          <IconMultipleInner
            ariaLabel="existing offer value props"
            valueProps={stepExistingOfferValueProps}
          />
        </IconMultipleWrapper>
        <CallToActionSection>
          <LetsTalkButton
            component={Link}
            to="/onboarding/get-started"
            onClick={() =>
              trackingInstance.track('clickDiscoverCTA-howItWorks', {
                'page-location': 'how-it-works-loan-offer'
              })
            }
          >
            Let&rsquo;s talk
          </LetsTalkButton>
          <DesktopDisplay>
            <OwnUpBody component="span" variant="body1">
              Need more knowledge? <CheckOutOurFaqs />
            </OwnUpBody>
          </DesktopDisplay>
          <MobileDisplay>
            <MobileNeedMoreKnowledge>
              <OwnUpBody variant="body1">Need more knowledge?</OwnUpBody>
              <CheckOutOurFaqs />
            </MobileNeedMoreKnowledge>
          </MobileDisplay>
        </CallToActionSection>
      </OwnUpGridWrapper>
    </Section>
  );
};
