import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const HowItWorksHeaderImage = () => (
  <StaticImage
    src="../../images/tyler.png"
    alt="Sales Manager Tyler smiles at the camera"
    placeholder="blurred"
    quality={100}
    loading="eager"
  />
);

const KatinaImage = () => (
  <StaticImage
    src="../../images/smiling-woman.png"
    alt="Own Up Sales Operations Manager Katina Nardi smiles at the camera"
    placeholder="blurred"
    quality={100}
    style={{ borderRadius: '50%' }}
  />
);

const MikeBobbleheadImage = () => (
  <StaticImage
    src="../../images/bobblehead.png"
    alt="A bobblehead figurine of a standing business person wearing a black suit and red tie"
    placeholder="blurred"
    quality={100}
    aspectRatio={1}
    imgStyle={{ borderRadius: '50%' }}
  />
);

const MikePointingAtMonitorImage = () => (
  <StaticImage
    src="../../images/man-pointing-at-computer-screen.png"
    alt="Own Up Co-founder Mike Tassone seated at a desk, pointing to a computer monitor while a colleague observes"
    placeholder="blurred"
    quality={100}
    aspectRatio={1}
    imgStyle={{ borderRadius: '50%' }}
  />
);

const BrentImage = () => (
  <StaticImage
    src="../../images/founder-smiling.png"
    alt="Own Up Co-founder Brent Shields smiles widely, looking off to the right of the camera"
    placeholder="blurred"
    quality={100}
    aspectRatio={1}
    imgStyle={{ borderRadius: '50%' }}
  />
);

export const howItWorksImages = {
  'smiling-woman.png': <KatinaImage />,
  'bobblehead.png': <MikeBobbleheadImage />,
  'man-pointing-at-computer-screen.png': <MikePointingAtMonitorImage />,
  'founder-smiling.png': <BrentImage />
};
