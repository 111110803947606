import React from 'react';
import {
  AcceptedOfferSVG,
  ChartSVG,
  CompareIcon,
  CreditIcon,
  LockIcon,
  PhoneSVG,
  TalkLargeIcon
} from '../../images/icons/reskin';

export const stepOneIconMultipleValueProps = [
  {
    icon: <LockIcon />,
    header: 'Secure & Confidential',
    body: "Create your free personalized profile in just 5 minutes. It's completely anonymous and your information is never sold."
  },
  {
    icon: <CreditIcon />,
    header: 'No SSN or hard credit inquiries',
    body: 'We deliver personalized loan offers without requiring a SSN. We only do a soft inquiry.'
  }
];

export const stepTwoHomeAdvisorProps = {
  imageFn: 'smiling-woman.png' as const,
  title: 'STEP TWO',
  headline: 'Meet your home advisor',
  body: `Buying a home is likely the biggest financial transaction of your life. It can be
  stressful, so you get a dedicated expert home advisor who provides you ongoing support
  every step of the way.`,
  valuePropsLabel: 'Benefits of having a home advisor',
  valueProps: [
    {
      icon: <PhoneSVG />,
      header: 'Unbiased advice',
      body: "We discuss your goals and what to expect during the process — it's not a sales call and there’s no obligation."
    },
    {
      icon: <ChartSVG />,
      header: 'Insight tools',
      body: 'See what you qualify for, compare lenders, and see actual prequalified rates.'
    },
    {
      icon: <AcceptedOfferSVG />,
      header: 'Preapproval letter generator',
      body: "We have the industry's first automated preapproval letter — generate updates on demand from any device."
    }
  ]
};

export const stepThreeImageMultipleValueProps = [
  {
    image: 'bobblehead.png' as const,
    data: {
      header: 'Pre-negotiated',
      body: 'We negotiate terms with our lender network so you can be confident in your offer.'
    }
  },
  {
    image: 'man-pointing-at-computer-screen.png' as const,
    data: {
      header: 'You see what we see',
      body: 'Our lenders pay us the same fee every time, so we show you every offer exactly as we see it.'
    }
  },
  {
    image: 'founder-smiling.png' as const,
    data: {
      header: 'With you every step',
      body: 'If you choose a lender in our network, we stay with you until closing to meet every obligation.'
    }
  }
];

export const stepExistingOfferValueProps = [
  {
    icon: <CompareIcon />,
    header: 'Compare offers',
    body: 'See how your loan offer compares to actual pre-qualified loan offers from the top-rated lenders in our network.'
  },
  {
    icon: <TalkLargeIcon />,
    header: 'Get better terms',
    body: 'We can help you negotiate better terms with your lender, saving you thousands.'
  }
];
