import { OwnUpSmallHeadlineBook } from '@rategravity/own-up-component-library';
import React from 'react';
import { OverlineText, OverlineWrapper } from '../../overline-text';
import { Headline } from './elements';

export const StepHeader = ({
  highlightText,
  headlineText
}: {
  highlightText: string | undefined;
  headlineText: string | undefined;
}) => (
  <React.Fragment>
    <OverlineWrapper>
      <OverlineText>{highlightText}</OverlineText>
    </OverlineWrapper>
    <Headline>
      <OwnUpSmallHeadlineBook variant="h2">{headlineText}</OwnUpSmallHeadlineBook>
    </Headline>
  </React.Fragment>
);
