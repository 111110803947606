import React from 'react';
import { stepOneIconMultipleValueProps } from '../../../data/content/how-it-works';
import { IconMultiple } from '../../icon-multiple';
import { StepHeader } from '../step-header';

export const Step1 = () => (
  <IconMultiple
    ariaLabel="Step one"
    sectionHeader={<StepHeader highlightText="STEP ONE" headlineText="Build your profile" />}
    valueProps={stepOneIconMultipleValueProps}
  />
);
