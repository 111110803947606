import { useTrackingInstance } from '@rategravity/1pt-lib';
import { FOG_20, OwnUpFillButtonSecondary } from '@rategravity/own-up-component-library';
import { Link } from 'gatsby';
import React from 'react';
import { stepThreeImageMultipleValueProps } from '../../../data/content/how-it-works';
import { ImageMultiple } from '../../image-multiple';
import { howItWorksImages as images } from '../../static-images/how-it-works';

const GetStartedButton = () => {
  const tracker = useTrackingInstance();
  const onClick = () =>
    tracker.track('Click how it works CTA', {
      'page-location': 'home-learn-how'
    });

  return (
    <OwnUpFillButtonSecondary component={Link} to="/onboarding/get-started" onClick={onClick}>
      Let&apos;s get started
    </OwnUpFillButtonSecondary>
  );
};

export const Step3 = () => (
  <ImageMultiple
    button={<GetStartedButton />}
    highlightText="STEP THREE"
    headlineText="Find the right loan for you"
    imageData={images}
    backgroundColor={FOG_20}
    valueProps={stepThreeImageMultipleValueProps}
  />
);
