import {
  OwnUpGridWrapper,
  OwnUpThemeProvider,
  ownUpWhiteTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import { stepTwoHomeAdvisorProps } from '../../../data/content/how-it-works';
import { IconMultiple } from '../../icon-multiple';
import { ImageWithText } from '../../image-with-text';
import { howItWorksImages as images } from '../../static-images/how-it-works';
import { StepHeadline, StepText, StepWrapper } from './elements';

export const Step2 = () => {
  const theme = ownUpWhiteTheme;
  const { title, imageFn, headline, body, valueProps, valuePropsLabel } = stepTwoHomeAdvisorProps;
  const image = images[imageFn];

  return (
    <OwnUpThemeProvider theme={theme}>
      <StepWrapper>
        <OwnUpGridWrapper>
          <ImageWithText {...{ title, image }}>
            <StepHeadline>{headline}</StepHeadline>
            <StepText variant="body2">{body}</StepText>
          </ImageWithText>
        </OwnUpGridWrapper>
        <IconMultiple ariaLabel={valuePropsLabel} theme={theme} valueProps={valueProps} />
      </StepWrapper>
    </OwnUpThemeProvider>
  );
};
