import { ownUpLightTheme, OwnUpThemeProvider } from '@rategravity/own-up-component-library';
import React from 'react';
import { Ankle } from '../components/ankle';
import { Body, Header } from '../components/header';
import { Step1, Step2, Step3, StepExistingOffer } from '../components/how-it-works';
import { Layout } from '../components/layout';
import { HowItWorksHeaderImage as HeaderImage } from '../components/static-images/how-it-works';

export const HowItWorks = () => {
  return (
    <Layout>
      <Header image={<HeaderImage />} headerText="We're your mortgage co-pilot">
        <Body>
          Own Up provides you with unbiased expertise that leads to better financial outcomes.
          Here&apos;s how:
        </Body>
      </Header>
      <Step1 />
      <Step2 />
      <Step3 />
      <OwnUpThemeProvider theme={ownUpLightTheme}>
        <StepExistingOffer />
      </OwnUpThemeProvider>
      <Ankle />
    </Layout>
  );
};

export default HowItWorks;
